<template>
<b-container fluid>
  <b-row class="text-white bg-kpmg-pink py-4 mb-4">
    <b-col>
      <h1 class="kstyle">Snapshot</h1>
    </b-col>
  </b-row>
  <b-row>
    <b-container>
      <b-row class="mb-4">
        <b-col>
          <b-card class="mb-4">
            <b-button v-b-toggle.collapse-1 class="mb-4">Configuration</b-button>
            <b-collapse id="collapse-1">
              <b-form inline class="mb-4">
                <h4 class="mr-2">Events</h4>
                <b-form-checkbox v-model="sectionEvents" :value="true" :unchecked-value="false"> </b-form-checkbox>
              </b-form>
              <span v-if="sectionEvents">
                <div>Set due date range</div>
                <b-form inline class="mb-4">
                  <label class="mr-2" for="dateInput">Start date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="eventsStartDate" required> </b-form-input>
                  <label class="mr-2" for="dateInput">End date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="eventsEndDate" required> </b-form-input>
                  <b-button variant="primary" v-on:click="loadEvents">Apply</b-button>
                </b-form>
              </span>

              <b-form inline class="mb-4">
                <h4 class="mr-2">News by type</h4>
                <b-form-checkbox v-model="sectionNewsType" :value="true" :unchecked-value="false"> </b-form-checkbox>
              </b-form>
              <span v-if="sectionNewsType">
                <div>Set news publication dates</div>
                <b-form inline class="mb-4">
                  <label class="mr-2" for="dateInput">Start date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="consultationsStartDate" required> </b-form-input>
                  <label class="mr-2" for="dateInput">End date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="consultationsEndDate" required> </b-form-input>
                  <b-button variant="primary" v-on:click="loadConsultations">Apply</b-button>
                </b-form>
              </span>

              <b-form inline class="mb-4">
                <h4 class="mr-2">News by topic</h4>
                <b-form-checkbox v-model="sectionNewsTopic" :value="true" :unchecked-value="false"> </b-form-checkbox>
              </b-form>
              <span v-if="sectionNewsTopic">
                <div>Set news publication dates</div>
                <b-form inline class="mb-4">
                  <label class="mr-2" for="dateInput">Start date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="newsTopicStartDate" required> </b-form-input>
                  <label class="mr-2" for="dateInput">End date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="newsTopicEndDate" required> </b-form-input>
                  <b-button variant="primary" v-on:click="loadNewsTopics">Apply</b-button>
                </b-form>
              </span>

              <b-form inline class="mb-4">
                <h4 class="mr-2">News by region and source</h4>
                <b-form-checkbox v-model="sectionNewsWithregtopics" :value="true" :unchecked-value="false"> </b-form-checkbox>
              </b-form>
              <span v-if="sectionNewsWithregtopics">
                <div>Set news publication dates</div>
                <b-form inline class="mb-4">
                  <label class="mr-2" for="dateInput">Start date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="newsWithtopicsStartDate" required> </b-form-input>
                  <label class="mr-2" for="dateInput">End date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="newsWithtopicsEndDate" required> </b-form-input>
                  <b-button variant="primary" v-on:click="loadNewsWithtopics">Apply</b-button>
                </b-form>
              </span>

              <b-form inline class="mb-4">
                <h4 class="mr-2">News by market abuse risk</h4>
                <b-form-checkbox v-model="sectionNewsRisks" :value="true" :unchecked-value="false"> </b-form-checkbox>
              </b-form>
              <span v-if="sectionNewsRisks">
                <div>Set news publication dates</div>
                <b-form inline class="mb-4">
                  <label class="mr-2" for="dateInput">Start date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="newsRiskStartDate" required> </b-form-input>
                  <label class="mr-2" for="dateInput">End date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="newsRiskEndDate" required> </b-form-input>
                  <b-button variant="primary" v-on:click="loadNewsRisks">Apply</b-button>
                </b-form>
              </span>

              <b-form inline class="mb-4">
                <h4 class="mr-2">All news by date</h4>
                <b-form-checkbox v-model="sectionNews" :value="true" :unchecked-value="false"> </b-form-checkbox>
              </b-form>
              <span v-if="sectionNews">
                <div>Set news publication dates</div>
                <b-form inline class="mb-4">
                  <label class="mr-2" for="dateInput">Start date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="newsStartDate" required> </b-form-input>
                  <label class="mr-2" for="dateInput">End date:</label>
                  <b-form-input id="dateInput" class="mr-2" type="date" v-model="newsEndDate" required> </b-form-input>
                  <b-button variant="primary" v-on:click="loadNews">Apply</b-button>
                </b-form>
              </span>

              <h4>Display control</h4>
              <div>Form display controls</div>
              <b-form inline class="mb-4">
                <b-form-checkbox id="checkbox-1" v-model="showNumbers" name="checkbox-1" :value="true" :unchecked-value="false">
                  Show numbers
                </b-form-checkbox>
              </b-form>
            </b-collapse>
          </b-card>

          <b-card class="mb-4">
            <h1 class="mb-4">Output</h1>

            <span v-if="sectionEvents">
              <div v-if="!loadingEvents" class="mb-4">
                <h4>
                  Events <span v-if="showNumbers">- {{ events.length }}</span>
                </h4>
                <div v-for="event in events" :key="'event' + event.id">
                  {{ event.date }}: {{ event.name }}
                  <span v-if="event.publications.length > 0">
                      <span v-for="(item, index) in event.publications" :key="'publication' + item.id">
                          <a :href="item.link" target="_blank"> | source {{ index + 1 }} </a>
                        </span>
                  </span>
                </div>
              </div>
              <span v-if="loadingEvents">Loading events... <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </span>

            <span v-if="sectionNewsType">
              <div v-if="!loadingConsultations" class="mb-4">
                <h4>News by type</h4>
                <div v-for="item in consultations" :key="'newsTag' + item.id">
                  <div class="mb-2">
                    <div>
                      <strong
                        >{{ item.name }} <span v-if="showNumbers">- {{ item.publications.length }}</span></strong
                      >
                    </div>
                    <div v-for="news in item.publications" :key="'newsInTag' + news.id">
                      {{ moment(news.date).format('YYYY-MM-DD') }} - {{ news.publisher }}: <a :href="news.link" target="_blank">{{ news.name }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="loadingConsultations">Loading news by topic... <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </span>

            <span v-if="sectionNewsTopic">
              <div v-if="!loadingNewsTopic" class="mb-4">
                <h4>News by topic</h4>
                <div v-for="topic in newsTopic" :key="'newsTopic' + topic.id">
                  <div class="mb-2">
                    <div>
                      <strong
                        >{{ topic.name }} <span v-if="showNumbers">- {{ topic.publications.length }}</span></strong
                      >
                    </div>
                    <div v-for="news in topic.publications" :key="'newsInTopic' + news.id">
                      {{ moment(news.date).format('YYYY-MM-DD') }} - {{ news.publisher }}: <a :href="news.link" target="_blank">{{ news.name }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="loadingNewsTopic">Loading news by topic... <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </span>

            <span v-if="sectionNewsWithregtopics">
              <div v-if="!loadingNewsWithtopics" class="mb-4">
                <h4>News by region and source</h4>
                <div v-for="countries in newsWithtopics" :key="'newsWithtopics' + countries.country">
                  <h5 class="mt-4">{{ countries.country }}</h5>
                  <div v-for="regulators in countries.regulators" :key="'newsWithtopics' + regulators.regulator">
                    <div class="mt-3">
                      <strong>
                        {{ regulators.regulator }}
                        <span v-if="showNumbers">- {{ regulators.news.length }}</span>
                      </strong>
                    </div>
                    <div v-for="news in regulators.news" :key="'newsWithtopics' + news.id">
                      {{ moment(news.date).format('YYYY-MM-DD') }} - {{ news.publisher }}: <a :href="news.link" target="_blank">{{ news.name }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="loadingNewsWithtopics">Loading news by topic... <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </span>

            <span v-if="sectionNewsRisks">
              <div v-if="!loadingNewsRisk" class="mb-4">
                <h4>News by market abuse risks</h4>
                <div v-for="item in newsRisk" :key="'newsRisk' + item.id">
                  <div class="mb-2">
                    <div>
                      <strong
                        >{{ item.name }} <span v-if="showNumbers">- {{ item.publications.length }}</span></strong
                      >
                    </div>
                    <div v-for="news in item.publications" :key="'newsInRisk' + news.id">
                      {{ moment(news.date).format('YYYY-MM-DD') }} - {{ news.publisher }}: <a :href="news.link" target="_blank">{{ news.name }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="loadingNewsRisk">Loading news by market risks... <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </span>

            <span v-if="sectionNews">
              <div v-if="!loadingNews" class="mb-4">
                <h4>
                  All news by date
                  <span v-if="showNumbers">- {{ news.length }}</span>
                </h4>
                <div v-for="item in news" :key="'news' + item.id">
                  {{ moment(item.date).format('YYYY-MM-DD') }} - {{ item.publisher }}: <a :href="item.link" target="_blank">{{ item.name }}</a>
                </div>
              </div>
              <span v-if="loadingNews">Loading all news by date... <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </span>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-row>
</b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'Snapshot',
  created: async function () {
    document.title = "KPMG Regulatory Horizon: Snapshot"
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.$stat.log({ page: 'snapshot', action: 'open snapshot' })
    await this.loadEvents()
    await this.loadConsultations()
    await this.loadNewsTopics()
    await this.loadNewsWithtopics()
    await this.loadNewsRisks()
    await this.loadNews()
  },
  data () {
    return {
      consultations: [],
      consultationsStartDate: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      consultationsEndDate: moment()
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      events: [],
      eventsStartDate: moment().format('YYYY-MM-DD'),
      eventsEndDate: moment()
        .add(14, 'days')
        .format('YYYY-MM-DD'),
      loadingConsultations: true,
      loadingEvents: true,
      loadingNews: true,
      loadingNewsRisk: true,
      loadingNewsTopic: true,
      loadingNewsWithtopics: true,
      news: [],
      newsStartDate: moment()
        .subtract(2, 'days')
        .format('YYYY-MM-DD'),
      newsEndDate: moment()
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      newsRiskStartDate: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      newsRiskEndDate: moment()
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      newsRisk: [],
      newsTopicStartDate: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      newsTopicEndDate: moment()
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      newsWithtopicsStartDate: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      newsWithtopicsEndDate: moment()
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      newsTopic: [],
      newsWithtopics: [],
      sectionEvents: true,
      sectionNews: true,
      sectionNewsRisks: true,
      sectionNewsTopic: true,
      sectionNewsType: true,
      sectionNewsWithregtopics: true,
      showNumbers: true
    }
  },
  methods: {
    loadConsultations: async function () {
      this.$logger.debug('loadingConsultations started')
      this.loadingConsultations = true
      try {
        let apiName = 'cosmos'
        let path = `/news/tags/betweendates/${this.consultationsStartDate}/${this.consultationsEndDate}`
        let response1 = await this.$Amplify.API.get(apiName, path)
        this.consultations = response1
        this.loadingConsultations = false
      } catch (e) {
        this.$logger.wrn('load ERROR: ', e)
      }
    },
    loadEvents: async function () {
      this.$logger.debug('loadingEvents started')
      this.loadingEvents = true
      try {
        let apiName = 'cosmos'
        let path = `/events/betweendates/${this.eventsStartDate}/${this.eventsEndDate}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.events = response
        this.loadingEvents = false
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
    },
    loadNews: async function () {
      this.$logger.debug('loadNews started')
      this.loadingNews = true
      try {
        let apiName = 'cosmos'
        let path = `/news/betweendates/${this.newsStartDate}/${this.newsEndDate}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.news = response
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
    },
    loadNewsRisks: async function () {
      this.$logger.debug('loadNewsRisks started')
      this.loadingNewsRisk = true
      try {
        let apiName = 'cosmos'
        let path = `/news/risks/betweendates/${this.newsRiskStartDate}/${this.newsRiskEndDate}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.newsRisk = response
        this.loadingNewsRisk = false
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
    },
    loadNewsTopics: async function () {
      this.$logger.debug('loadNewsTopics started')
      this.loadingNewsTopic = true
      try {
        let apiName = 'cosmos'
        let path = `/news/topics/betweendates/${this.newsTopicStartDate}/${this.newsTopicEndDate}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.newsTopic = response
        this.loadingNewsTopic = false
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
    },
    loadNewsWithtopics: async function () {
      this.$logger.debug('loadNewsTopics started')
      this.loadingNewsWithtopics = true
      try {
        let apiName = 'cosmos'
        let path = `/news/withregtopic/betweendates/${this.newsWithtopicsStartDate}/${this.newsWithtopicsEndDate}`
        let response = await this.$Amplify.API.get(apiName, path)
        let c = _.groupBy(response, 'countryName')
        this.newsWithtopics = _.map(c, function (value, key) {
          let temp = _.groupBy(value, 'publisher')
          return {
            country: key,
            regulators: _.map(temp, function (value, key) {
              return {
                regulator: key,
                news: value
              }
            })
          }
        })
        this.loadingNewsWithtopics = false
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
    },
    moment: function (value) {
      return moment(value)
    }
  }
}
</script>

<style></style>
